<template>
    <AuthLayout>
        <Button 
            color="primary"
            block
            @click.native="$router.push({ name: 'create-retailer' })"
            class="my-3"
        >
            Create New Retailer
        </Button>
        <table class="table table-borderless hover" ref="retailersTable">
            <thead>
                <tr>
                    <th class="vert-center text-center">Account Number</th>
                    <th class="vert-center text-center">Company</th>
                    <th class="vert-center text-center">Activated</th>
                    <th class="vert-center text-center">Created At</th>
                    <th class="vert-center text-center">Options</th>
                </tr>
            </thead>
        </table>
    </AuthLayout>
</template>

<script>
    import $ from 'jquery'
    import { mapGetters } from 'vuex'
    import swal from 'sweetalert'

    export default {
        name: 'Retailers',

        computed: {
            ...mapGetters('retailer', [
                'populatedRetailers',
                'populatedRetailersCount',
                'dataTableColumns'
            ]),
        },

        data: () => ({
            retailersTable: null,
            dataTableElem: null,
            clickedPage: 0
        }),

        methods: {
            initDataTable() {
                this.retailersTable = this.dataTableElem.DataTable({
                    responsive: true,
                    serverSide: true,
                    processing: true,
                    language: {
                        info: "Displaying _START_ to _END_ of _TOTAL_ retailers",
                        infoFiltered: ""
                    },
                    ajax: async function (data, callback) {
                        await this.$store.dispatch('retailer/populateRetailers', {
                            draw: data['draw'],
                            limit: data['length'],
                            search: data['search']['value'],
                            column: this.dataTableColumns[data['order'][0]['column']],
                            direction: data['order'][0]['dir'],
                            page: this.clickedPage
                        })

                        this.clickedPage = 0

                        callback({
                            draw: data.draw,
                            data: this.populatedRetailers,
                            recordsFiltered: this.populatedRetailersCount
                        })
                    }.bind(this),
                    columnDefs: [
                        {
                            className: 'align-middle text-center',
                            targets: 0
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 1
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 2,
                            render: function (data) {
                                if (data == 'active') {
                                    return '<p class="text-center m-0 p-0"><i class="fa fa-check text-success"></i></p>';
                                }
                                return '<p class="text-center m-0 p-0"><i class="fa fa-times text-danger"></i></p>';
                            }
                        },
                        {
                            className: 'align-middle text-center',
                            targets: 3
                        },
                        {
                            targets: 4,
                            render: (data, type, row, meta) => {
                                var editBtn = '<button id="editRetailer_' + meta.row + '" class="btn btn-info btn-sm btn-block m-2 edit-button">' +
                                                    '<i class="fa fa-pencil mr-2"></i>' +
                                                    'Edit This Retailer' +
                                                '</button>'
                                
                                var removeBtn = '<button id="removeRetailer_' + meta.row + '" class="btn btn-danger btn-sm btn-block m-2 remove-button">' +
                                                    '<i class="fa fa-trash mr-2"></i>' +
                                                    'Remove This Retailer' +
                                                '</button>'

                                return '<div class="my-auto pr-3">' +
                                            editBtn +
                                            removeBtn +
                                        '</div>';
                            },
                            sortable: false
                        }
                    ]   
                });

                this.autorefresh()
                this.checkClickedPage()
                this.onVueFunctions()
            },

            autorefresh() {
                setInterval(() => {
                    this.retailersTable.ajax.reload(null, false)
                }, 300000)
            },

            checkClickedPage() {
                this.dataTableElem.on('page.dt', () => {
                    let info = this.retailersTable.page.info()
                    this.clickedPage = ++info.page
                })
            },

            onVueFunctions() {
                const self = this

                $('tbody', this.$refs.retailersTable).on('click', '.edit-button', function () {
                    var row = $(this).attr("id").match(/\d+/)[0]
                    var uid = self.retailersTable.row(row).data()[4]
                    self.onEditRetailer(uid)
                })

                $('tbody', this.$refs.retailersTable).on('click', '.remove-button', function () {
                    var row = $(this).attr("id").match(/\d+/)[0]
                    var uid = self.retailersTable.row(row).data()[4]
                    self.onRemoveRetailer(uid)
                })
            },

            async onEditRetailer(uid) {
                this.$router.push({
                    name: 'update-retailer',
                    params: {
                        retailerID: uid 
                    } 
                })
            },

            async onRemoveRetailer(uid) {
                const message = {
					title: 'Are you sure you want to remove this retailer?',
					text: "The selected Lifebox retailer will be deleted by the system. This action is irreversible.",
					icon: 'warning',
					buttons: true,
					dangerMode: true
				}

				if (await swal(message)) {
					await this.$store.dispatch('retailer/removeRetailer', uid)
                    this.retailersTable.ajax.reload(null, false)
                    swal("Retailer Deleted Successfully", "Retailers list will be updated now, Lifebox Admin.")
				}
            }
        },

        mounted() {
            this.dataTableElem = $(this.$refs.retailersTable)
            this.initDataTable()
        },
    }
</script>

<style lang="scss" scoped>
    .circle-logo {
        border: 1px solid #f8f8f8;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    .vert-center {
        vertical-align: middle !important;
    }
</style>